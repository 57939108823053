<template>
    <div class="main-container">
        <div class="container-content">

            <a class="image" href="https://my.medel.com">
                <img class="resize" alt="myMED-EL" src="https://widgets.my.medel.com/menu/medel-logo.svg">
            </a>
            <div class="centertop">
                <h1>The Processor Configuration Backup service ended in June 2023.</h1>
                <div class="message">
                    We are currently working on an improved user experience for this type of service. Any new service will be available
                    to you through your myMED-EL account. <br> In the meantime, please contact your local MED-EL representative if you need any assistance.
                </div>
                <button onclick="window.location.href='http://my.medel.com';">To the myMED-EL Portal</button>
            </div>
        </div>
        <the-portal-footer class="footer"/>
    </div>
</template>

<script>
import ThePortalFooter from '@/components/ThePortalFooter.vue'
export default {
    components: {
        ThePortalFooter
}
}

</script>

<style scoped>
  .container-content{
    margin-bottom: auto;
    padding-bottom: 5%;
  }
.footer {
    width: 100%;
    position: relative;
}

body{
    margin-bottom: 7cm;
}

.message{
    font-family: OpenSans,Arial,Helvetica,sans-serif;
    color: #646464;
    line-height: 1.5;
    margin-top: 0;
}
h1 {
    text-align: center;
    color: #c60c30;
}
button{
    background-color: #c60c30;
    color: #fff;
    text-align: center;
    font-family: SignaPro,Arial;
    font-weight: 300;
    padding-left: 2em;
    padding-right: 2em;
    padding: 0.65em 1em 0.5em;
    font-size: 16px!important;
    display: block;
    margin: auto;
    border: 0;
    border-radius: 0;
    cursor: pointer;
    max-width: 400px;
    }

.centertop {
    margin-top: 3%;
}

.message {
    padding-bottom: 50px;
    text-align: center;
}

img.resize {
    width:200px;
    height:40px;
    margin-right: 0px;
    margin-left: auto;
}

.image {
    display: flex;
    justify-content: right;
    margin-right: 2%;
    margin-top: 1%;
}

.main-container{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
</style>
